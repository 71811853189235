@import url(https://fonts.googleapis.com/css?family=Old+Standard+TT);
.Typography {
  display: block;
  margin: 0;
}

/* Separate styles when necessary */
.Typography--h1,
.Typography--h2,
.Typography--h3,
.Typography--h4,
.Typography--h5,
.Typography--h6 {
  font-size: 20px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.2em;
  text-transform: uppercase;
  color: #000;
}

.Typography--body {
  font-size: 16px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4em;
  color: #000;
}

.Typography--gutterBottom {
  margin-bottom: 0.35em;
}

.Typography--paragraph {
  margin-bottom: 16px;
}

.ButtonBase {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: none;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  color: inherit;
}

.ButtonBase[disabled] {
  pointer-events: none;
  cursor: default;
}

.Button {
  padding: 5px 10px;
  min-width: 160px;
  border: 1px solid #000;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 20px;
}

.Button:hover {
  background: #000;
  color: #fff;
}

/* Special ugly button hover fix */
.Scatter-overlay .Button {
  background: #000;
  color: #fff;
}

.Flipper {
  width: 100%;
  height: 100%;
}

.Flipper > * {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
  user-drag: none;
}

.Marquee {
  display: flex;
  padding: 0;
  width: 100%;
  overflow: hidden;
  /* @todo: Dynamically get height instead */
  height: 66px;
}

.Marquee:not(.Marquee-ready) {
  display: none;
}

.Marquee-viewport {
  display: flex;
  position: absolute;
}

.Marquee img {
  max-width: none;
  margin-left: 0;
}

.Slider:not(.Slider-ready) {
  display: none;
}

.Card {
  flex-direction: column;
  padding: 25px;
  border: 1px solid #000;
  border-radius: 20px;
  background: #fff;
  text-align: center;
  width: 100%;
}

.CardMedia {
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0;
}

.CardMedia::before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 100%;
}

.CardMedia img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
  user-drag: none;
}

.CardMedia-img:first-child {
  z-index: -1;
}

.Card:hover .CardMedia-img:last-child,
.CardMedia:hover .CardMedia-img:last-child {
  opacity: 0;
}

/* Special ugly hover fix */
.Scatter-overlay .CardMedia-img:last-child {
  opacity: 0;
}

.Scatter {
  position: relative;
}

.Scatter-overlay {
  display: none;
  pointer-events: none;
}

.Scatter-overlay > * {
  position: absolute;
}

.Scatter-overlay > img {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

body {
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.App {
}

.App-Flipper {
  position: fixed;
  top: 0;
  left: 0;
}

.App-grid {
  position: relative;
  z-index: 9;
  max-width: calc(100% - (66px * 1) - 20px);
  margin: 30px auto;
}
@media (min-width: 370px) {
  .App-grid {
    max-width: calc(100% - (66px * 2) - 20px);
  }
}
@media (min-width: 600px) {
  .App-grid {
    display: none;
  }
}

.App-grid > * + * {
  margin-top: 10px;
}

.App-SliderWrapper {
  position: absolute;
  /*z-index: 9;*/
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media (max-width: 599px) {
  .App-SliderWrapper {
    display: none;
  }
}

.App-Slider-item {
  display: block;
  margin-right: 10px;
  max-width: 40vh;
  width: 100%;
}

.App-MarqueeTop {
  position: fixed;
  top: 10px;
}
@media (max-width: 599px) {
  .App-MarqueeTop {
    /* transform: rotate(90deg);
    transform-origin: left bottom;
    width: 100vh;
    top: -66px; */
    -webkit-transform: rotate(-90deg) translate(0px, 66px);
            transform: rotate(-90deg) translate(0px, 66px);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    width: 100vh;
    top: auto;
    bottom: 0;
    left: 5px;
  }
}

.App-MarqueeBottom {
  position: fixed;
  bottom: 10px;
}
@media (max-width: 599px) {
  .App-MarqueeBottom {
    -webkit-transform: rotate(90deg) translate(66px, 0);
            transform: rotate(90deg) translate(66px, 0);
    -webkit-transform-origin: right top;
            transform-origin: right top;
    width: 100vh;
    bottom: 0;
    right: 5px;
  }
}

