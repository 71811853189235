@import url('https://fonts.googleapis.com/css?family=Old+Standard+TT');

body {
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.App {
}

.App-Flipper {
  position: fixed;
  top: 0;
  left: 0;
}

.App-grid {
  position: relative;
  z-index: 9;
  max-width: calc(100% - (66px * 1) - 20px);
  margin: 30px auto;
}
@media (min-width: 370px) {
  .App-grid {
    max-width: calc(100% - (66px * 2) - 20px);
  }
}
@media (min-width: 600px) {
  .App-grid {
    display: none;
  }
}

.App-grid > * + * {
  margin-top: 10px;
}

.App-SliderWrapper {
  position: absolute;
  /*z-index: 9;*/
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
@media (max-width: 599px) {
  .App-SliderWrapper {
    display: none;
  }
}

.App-Slider-item {
  display: block;
  margin-right: 10px;
  max-width: 40vh;
  width: 100%;
}

.App-MarqueeTop {
  position: fixed;
  top: 10px;
}
@media (max-width: 599px) {
  .App-MarqueeTop {
    /* transform: rotate(90deg);
    transform-origin: left bottom;
    width: 100vh;
    top: -66px; */
    transform: rotate(-90deg) translate(0px, 66px);
    transform-origin: left bottom;
    width: 100vh;
    top: auto;
    bottom: 0;
    left: 5px;
  }
}

.App-MarqueeBottom {
  position: fixed;
  bottom: 10px;
}
@media (max-width: 599px) {
  .App-MarqueeBottom {
    transform: rotate(90deg) translate(66px, 0);
    transform-origin: right top;
    width: 100vh;
    bottom: 0;
    right: 5px;
  }
}
