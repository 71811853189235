.Marquee {
  display: flex;
  padding: 0;
  width: 100%;
  overflow: hidden;
  /* @todo: Dynamically get height instead */
  height: 66px;
}

.Marquee:not(.Marquee-ready) {
  display: none;
}

.Marquee-viewport {
  display: flex;
  position: absolute;
}

.Marquee img {
  max-width: none;
  margin-left: 0;
}
