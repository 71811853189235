.Typography {
  display: block;
  margin: 0;
}

/* Separate styles when necessary */
.Typography--h1,
.Typography--h2,
.Typography--h3,
.Typography--h4,
.Typography--h5,
.Typography--h6 {
  font-size: 20px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.2em;
  text-transform: uppercase;
  color: #000;
}

.Typography--body {
  font-size: 16px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4em;
  color: #000;
}

.Typography--gutterBottom {
  margin-bottom: 0.35em;
}

.Typography--paragraph {
  margin-bottom: 16px;
}
