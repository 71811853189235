.CardMedia {
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0;
}

.CardMedia::before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 100%;
}

.CardMedia img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
  user-drag: none;
}

.CardMedia-img:first-child {
  z-index: -1;
}

.Card:hover .CardMedia-img:last-child,
.CardMedia:hover .CardMedia-img:last-child {
  opacity: 0;
}

/* Special ugly hover fix */
.Scatter-overlay .CardMedia-img:last-child {
  opacity: 0;
}
