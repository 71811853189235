.Scatter {
  position: relative;
}

.Scatter-overlay {
  display: none;
  pointer-events: none;
}

.Scatter-overlay > * {
  position: absolute;
}

.Scatter-overlay > img {
  transition: all 150ms ease;
}
