.Button {
  padding: 5px 10px;
  min-width: 160px;
  border: 1px solid #000;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 20px;
}

.Button:hover {
  background: #000;
  color: #fff;
}

/* Special ugly button hover fix */
.Scatter-overlay .Button {
  background: #000;
  color: #fff;
}
