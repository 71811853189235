.Flipper {
  width: 100%;
  height: 100%;
}

.Flipper > * {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
  user-drag: none;
}
